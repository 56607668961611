@import '~@rentacenter/racstrap/src/styles/vars';

.goals {
  .weekPicker {
    margin-top: pixelToRem(33);
    width: pixelToRem(200);
  }

  .section {
    margin: $SPACE_LARGER 0 $SPACE_LARGEST; 

    & > h3 {
      font-weight: 700;
      font-size: $LARGE-FONT-SIZE;
      margin: $SPACE_REGULAR 0;
    }
  }
}
.datePickerWrap {
  display: flex;
  align-items: flex-end;

  .datePicker {
    margin-right: $SPACE_MEDIUM;
    width: pixelToRem(180);
    height: pixelToRem(47);
  }
}


.datesRow {
  display: flex;
  gap: pixelToRem(32);
  margin-top: 2rem;
}
