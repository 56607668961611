@import "~@rentacenter/racstrap/src/styles/vars";

.timeSlotSelector {
  .legend {
    float: right;
    display: flex;

    & > div {
      display: flex;
      align-items: center;
      margin-left: $SPACE_LARGE;

      &::before {
        display: block;
        content: " ";
        width: pixelToRem(16);
        height: pixelToRem(16);
        border-radius: pixelToRem(2);
        margin-right: $SPACE_SMALLER;
      }

      &.available::before {
        border: pixelToRem(1) solid $NEUTRAL_04;
      }

      &.blocked::before {
        border: pixelToRem(1) solid $NEUTRAL_06;
        background: repeating-linear-gradient(
            -45deg,
            transparent,
            transparent pixelToRem(2),
            $NEUTRAL_06 pixelToRem(2),
            $NEUTRAL_06 pixelToRem(8)
        );
      }

      &.busy::before {
        border: pixelToRem(1) solid $NEUTRAL_06;
        background-color: $NEUTRAL_06;
      }
    }
  }

  .title {
    font-weight: 600;
    font-size: $NORMAL-FONT-SIZE;
    line-height: pixelToRem(24);
  }

  .subtitle {
    font-size: $SMALL-FONT-SIZE;
    line-height: pixelToRem(21);
  }

  .error {
    font-family: "Open Sans", sans-serif;
    color: $STATUS_RED_02;
    font-size: pixelToRem(12);
    margin: 0;
    margin-top: $SPACE_TINY;
  }

  .datePicker {
    display: flex;
    width: 100%;
    padding-right: $SPACE_REGULAR / 2;
    margin-bottom: $SPACE_MEDIUM;
    div:first-child {
      min-width: pixelToRem(390);
      margin-right: pixelToRem(16);
    }
    label span:nth-child(2) {
      width: pixelToRem(125);
    }
  }

  .days {
    display: flex;
    margin-top: $SPACE_MEDIUM;

    .day {
      margin: 0 ($SPACE_REGULAR / 2);

      &:first-child {
        margin-left: 0
      }

      &:last-child {
        margin-right: 0
      }
    }
  }
}
