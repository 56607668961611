@import '~@rentacenter/racstrap/src/styles/vars';

.logsTable {
  table-layout: fixed;

  tbody tr {
    box-shadow: none;
  }

  td, th {
    font-size: $SMALL-FONT-SIZE;
    vertical-align: top;
  }

  td {
    font-weight: 400;
  }

  .action {
    font-weight: 600;
  }

  .time {
    margin-top: $SPACE_TINY;
  }

  .dateTimeHeader, .dateColumn {
    text-align: right;
  }

  .apiError {
    padding-top: $SPACE_LARGE;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      font-size: $NORMAL-FONT-SIZE;
      svg {
        margin-right: $SPACE_SMALLER;
        color: $STATUS_RED_01;
      }
    }
  }

  .emptyList {
    font-size: $SMALL-FONT-SIZE;
    color: $NEUTRAL_03;
    margin-top: $SPACE_LARGER;
    font-weight: normal;
    text-align: center;
  }

}
