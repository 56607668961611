@import '~@rentacenter/racstrap/src/styles/vars';

.chip {
  display: inline-flex;
  align-items: center;
  text-align: center;
  margin: $SPACE_TINY $SPACE_SMALLER $SPACE_TINY 0;
  cursor: pointer;
  height: pixelToRem(27);
  white-space: nowrap;
  border-radius: pixelToRem(18);
  vertical-align: middle;
  justify-content: center;
  border: pixelToRem(1) solid currentColor;

  .label {
    padding: $SPACE_SMALLER;
    font-size: $TINY-FONT-SIZE;
    line-height: pixelToRem(18);
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    color: currentColor;
    
    .close {
      margin-left: pixelToRem(6);
    }
  }
}

.defaultBorderColor{
  border: pixelToRem(1) solid $NEUTRAL_06;
}
                               