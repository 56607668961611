@import '~@rentacenter/racstrap/src/styles/vars';

.priority {
  text-align: center;

  &.low {
    color: $STATUS_GREEN_01;
  }

  &.medium {
    color: $STATUS_YELLOW_06;
  }

  &.high {
    color: $STATUS_RED_01;
  }
}
