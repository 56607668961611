@import '~@rentacenter/racstrap/src/styles/vars';

.toDo, .inProgress {
  &::before {
    content: " ";
    display: inline-block;
    width: pixelToRem(10);
    height: pixelToRem(10);
    border-radius: 100%;
    margin-right: $SPACE_SMALLER;
    margin-top: pixelToRem(1);
  }
}

.completed, .cancelled {
  .iconStyle {
    margin-right: $SPACE_TINY;
    font-size: $NORMAL-FONT-SIZE;
    color: $STATUS_GREEN_01;
    vertical-align: middle;
  }
  .red {
    color: $STATUS_RED_01;
  }
}

.toDo::before {
  background-color: $PRIMARY_01;
}

.completed::before {
  background-color: $STATUS_GREEN_01;
}
