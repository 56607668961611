@import '~@rentacenter/racstrap/src/styles/vars';

.searchEvent {
  margin-right: $SPACE_MEDIUM;
  width: pixelToRem(320);
  background-color: white;

  .searchIcon {
    color: $NEUTRAL_04;
  }

  .options {
    z-index: 3;

    .primaryRow {
      display: flex;
      font-size: $SMALL-FONT-SIZE;
      margin-bottom: pixelToRem(2);

      .statusIcon {
        margin-right: $SPACE_TINY;
        font-size: $TINY-FONT-SIZE;
        align-self: center;
      }

      .ownerName {
        flex: 1;
      }
    }

    .secondaryRow {
      display: flex;
      font-size: $TINY-FONT-SIZE;
      color: $NEUTRAL_03;

      .address {
        flex: 1;
        max-width: pixelToRem(210);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: auto;
      }
    }
  }

  .criteriaOptions {
    z-index: 4;
    right: pixelToRem(-1);
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: $NEUTRAL_04;
    min-width: pixelToRem(100);
  }

  .input:hover .criteriaOptions,
  .criteriaOptions:hover {
    border-color: $NEUTRAL_03;
  }

  .criteriaButton .criteriaLabel {
    margin-right: $SPACE_MEDIUM;
  }
}

@media (max-width: 1679px) {
  .searchEvent {
    width: pixelToRem(250);

    .options .secondaryRow .address {
      max-width: pixelToRem(150);
    }
  }
}
