@import '~@rentacenter/racstrap/src/styles/vars';
@import '../../styles/breakpoints.scss';

$upcomingEventsWidth: pixelToRem(250);
$calendarMargin: pixelToRem(80);

.calenderWrapper {
  display: flex;
  margin-top: $SPACE_LARGER;
  height: calc(100% - #{$SPACE_LARGE} - #{$SPACE_LARGER});

  .upcomingEvents {
    width: $upcomingEventsWidth;
    margin-bottom: $SPACE_LARGE;
    position: relative;
    flex-shrink: 0;
  }

  .calendar {
    margin-bottom: $SPACE_LARGE;
    padding: $SPACE_REGULAR $SPACE_MEDIUM;
    padding-bottom: $SPACE_SMALLER;
    margin-left: $calendarMargin;
    display: flex;
    flex-direction: column;
    width: calc(100% - #{$upcomingEventsWidth} - #{$calendarMargin});

    .timeslots {
      flex: 1;
      position: relative;
      min-height: 0;
      overflow-y: auto;
      margin-left: pixelToRem(-80);
      padding-top: $SPACE_SMALLER;
    }

    .storeClosed {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-style: italic;
    }
  }

  @include bigDesktopDown {
    & {
      height: calc(100% - #{$SPACE_LARGER});
    }

    .upcomingEvents {
      display: none;
    }

    .calendar {
      $calendarMargin: pixelToRem(60);
      width: calc(100% - #{$calendarMargin});
      margin-left: $calendarMargin;
    }
  }
}
