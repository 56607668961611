@import '~@rentacenter/racstrap/src/styles/vars';

.noStoreSelected {
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  img {
    width: pixelToRem(164);
    height: pixelToRem(119);
    margin-bottom: $SPACE_LARGEST;
  }
  h1 {
    color: $NEUTRAL_01;
    margin-bottom: pixelToRem(10);
    font-size: $LARGER-FONT-SIZE;
    font-weight: bold;
  }
  span {
    color: $NEUTRAL_02;
    font-size: $NORMAL-FONT-SIZE;
    font-weight: 600;
    line-height: pixelToRem(27);
    text-align: center;
  }
}
