@import '~@rentacenter/racstrap/src/styles/vars';

.container {
  .row {
    margin-top: $SPACE_REGULAR;
    max-width: pixelToRem(403);

    &.split {
      display: flex;

      .left {
        width: pixelToRem(232);
        margin-right: $SPACE_REGULAR;
      }

      .right {
        flex: 1;
        width: 100%;
      }
    }
  }
  .fullWidth {
    max-width: unset;
  }
}


.timeSlotsContainer {
  margin-top: $SPACE_LARGE;

  .title {
    color: $NEUTRAL_01;
    font-weight: 600;
    font-size: $NORMAL-FONT-SIZE;
    line-height: pixelToRem(24);
  }

  .selectionText {
    height: pixelToRem(21);
    margin-top: $SPACE_TINY;
    font-size:  $SMALL-FONT-SIZE;
    line-height: pixelToRem(21);
  }

  .timeSlots {
    margin-top: $SPACE_MEDIUM;
  }

}
