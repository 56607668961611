@import '~@rentacenter/racstrap/src/styles/vars';

$minCardWidth: pixelToRem(290);
$maxCardWidth: pixelToRem(446);
$cardHeight: pixelToRem(145);

.storeGoalCard {
  flex: 1;
  position: relative;
  margin-top: $SPACE_REGULAR;
  margin-right: $SPACE_REGULAR;
  height: $cardHeight;
  min-width: $minCardWidth;
  max-width: $maxCardWidth;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > span:first-child {
    margin-bottom: $SPACE_LARGEST;
  }
}

.storeGoalCard:last-child {
  margin-right: 0;
}

.title {
  color: $NEUTRAL_01;
  font-weight: 600;
  font-size: $BIG-FONT-SIZE;

  svg {
    margin-right: $SPACE_TINY;
    color: $NEUTRAL_03;
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
}
