@import '~@rentacenter/racstrap/src/styles/vars';

.title {
  width: 100%;
}
.content {
  margin-top: $SPACE_TINY;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: pixelToRem(35);
}
.footer {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}
