@import '~@rentacenter/racstrap/src/styles/vars';

.progressBar {
  $negativeColor: $STATUS_RED_01;
  $lowColor: $PRIMARY_01;
  $mediumColor: $LABEL_YELLOW;
  $highColor: $LABEL_GREEN_2;

  .value {
    $valueHeight: pixelToRem(27);

    position: relative;
    height: $valueHeight;

    span {
      font-weight: 600;
      font-size: $BIG-FONT-SIZE;
      line-height: $valueHeight;
      position: absolute;
      color: $lowColor;
      margin-right: -0.35rem;

      &.negative {
        color: $negativeColor;
        margin-right: 0;
      }

      &.medium {
        color: $mediumColor;
        margin-right: 0;
      }

      &.high {
        color: $highColor;
        margin-left: 0;
      }
    }
  }

  .bar {
    $borderRadius: pixelToRem(12);

    position: relative;
    height: pixelToRem(14);
    background-color: $NEUTRAL_06;
    border-radius: $borderRadius;

    .negativeDir,
    .positiveDir {
      position: absolute;
      height: 100%;
    }

    .negativeDir {
      right: 50%;
      border-top-left-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
    }

    .positiveDir {
      left: 50%;
      border-top-right-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
    }

    div {
      background-color: $lowColor;

      &.negative {
        background-color: $negativeColor;
      }

      &.medium {
        background-color: $mediumColor;
      }

      &.high {
        background-color: $highColor;
      }
    }
  }

  .ticks {
    display: flex;
    margin-top: $SPACE_TINY;

    .min,
    .max {
      width: 50%;
      text-align: right;
      height: pixelToRem(21);
      line-height: pixelToRem(21);
      font-weight: 600;
      font-size: $NORMAL-FONT-SIZE;
    }

    .min {
      border-right: pixelToRem(1) dashed $NEUTRAL_02;
      padding-right: $SPACE_TINY;
    }
  }
}
