@import '~@rentacenter/racstrap/src/styles/vars';

.tasksList {
  height: 100%;
  display: flex;
  flex-direction: column;

  .columns {
    display: flex;
    flex: 1;
    min-height: 0;
    width: 80%;
  }
}
