@import '~@rentacenter/racstrap/src/styles/vars';

@import '../CollectionGoals.module';

.skeletonWrapper {
  display: flex;
  flex-wrap: wrap;

  .loadingCard {
    flex: 1;
    margin-top: $SPACE_REGULAR;
    margin-right: $SPACE_REGULAR;
    min-width: $minCardWidth;
    max-width: $maxCardWidth;

    & > span:first-child {
      margin-bottom: $SPACE_LARGEST;
    }
  }

  .loadingCard:last-child {
    margin-right: 0;
  }
}
