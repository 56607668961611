@import '~@rentacenter/racstrap/src/styles/vars';

.form {
  margin-top: $SPACE_MEDIUM;
  display: flex;
  align-items: flex-end;
  width: 100%;

  .content {
    width: 100%;
    height: pixelToRem(86);
    margin-right: $SPACE_MEDIUM;
  }

  .add {
    height: pixelToRem(86);
    button {
      margin-top: pixelToRem(22);
      margin-bottom: pixelToRem(11);
    }
  }
}
