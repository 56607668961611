@import '~@rentacenter/racstrap/src/styles/vars';

.card {
  margin-top: $SPACE_LARGE;
  width: pixelToRem(1047);

  .title {
    color: $NEUTRAL_01;
    font-weight: 600;
    font-size: pixelToRem(16);
    line-height: pixelToRem(24);
  }
}

.container {
  $columnMargin: $SPACE_REGULAR;

  display: flex;
  flex-direction: column;

  .row {
    margin: $SPACE_MEDIUM  ($columnMargin / -2) 0;
    display: flex;

    .column {
      padding: 0 ($SPACE_REGULAR / 2);
      max-width: 25%;
      flex-basis: 25%;
      flex-grow: 0;
    }

    .half {
      max-width: 50%;
      flex-basis: 50%;
    }

    .full {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}
