@import '~@rentacenter/racstrap/src/styles/vars';

.subtitle {
  color: $NEUTRAL_01;
  font-size: $NORMAL-FONT-SIZE;
  font-weight: 600;

  form {
    display: block;
    margin-top: $SPACE_MEDIUM;
    height: pixelToRem(86);

    .label {
      font-weight: 400;
    }
  }
}
