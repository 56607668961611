@import '../../../styles/breakpoints';
@import 'vars';

@import '~@rentacenter/racstrap/src/styles/vars';

.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: $FOOTER_HEIGHT;
  background-color: #fff;
  box-shadow: pixelToRem(7) pixelToRem(2) pixelToRem(15) 0 rgba(0, 0, 0, 0.19);
  z-index: 8;
  display: flex;
  justify-content: stretch;
  align-items: center;
  padding: $SPACE_LARGE $SPACE_REGULAR;

  [collapsed-sidebar] &.inContainer {
    left: pixelToRem(60);
  }
}

.btnWrapper {
  display: flex;
  justify-content: space-between;
  flex: 1;

  > div {
    display: flex;
    align-items: center;
  }
}
