
@import '~@rentacenter/racstrap/src/styles/vars';

.logsTable {

  .source {
    font-weight: 600;
    color: $NEUTRAL_03;
    margin-top: $SPACE_SMALLER;
    svg {
      height: pixelToRem(16);
      width: pixelToRem(16);
      vertical-align: middle;
      margin-right: $SPACE_TINY;
    }
  }
  .userName {
    max-width: pixelToRem(160);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .dateTimeHeader, .dateColumn {
    text-align: right;
  }

  .action {
    font-weight: 600;
  }

  .reason {
    font-weight: 400;
    color: $NEUTRAL_01;
  }

  .Deleted {
    color: $STATUS_YELLOW_06;
  }

  .Canceled {
    color: $STATUS_RED_01;
  }

  .apiError {
    padding-top: $SPACE_LARGE;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      font-size: $NORMAL-FONT-SIZE;
      svg {
        margin-right: $SPACE_SMALLER;
        color: $STATUS_RED_01;
      }
    }
  }

  .emptyList {
    font-size: $SMALL-FONT-SIZE;
    color: $NEUTRAL_03;
    margin-top: $SPACE_LARGER;
    font-weight: normal;
    text-align: center;
  }

}

