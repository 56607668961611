@import "~@rentacenter/racstrap/src/styles/vars";

.eventDetails {
  font-size: $NORMAL-FONT-SIZE;
  line-height: pixelToRem(24);
  font-weight: 400;

  .name {
    font-weight: 600;
    font-size: $LARGE-FONT-SIZE;

    svg {
      margin-right: $SPACE_SMALLER;
    }

  }

  .statusButton {
    padding: $SPACE_SMALLER $SPACE_SMALL;

    svg {
      height: pixelToRem(14);
      width: pixelToRem(14);
    }
  }

  .cancelEvent {
    margin-left: $SPACE_REGULAR;
  }

  .eventStatus {
    display: flex;
    justify-content: space-between;
    margin-top: pixelToRem(13);
    margin-bottom: $SPACE_LARGE;
    align-items: center;

    .statusField {
      display: flex;
      .status::before {
        width: pixelToRem(15);
        height: pixelToRem(15);
        margin-top: $SPACE_TINY;
      }
    }
  }

  .label {
    font-weight: 600;
    margin-right: $SPACE_TINY;
    white-space: nowrap;
  }

  .row {
    display: flex;
    margin-bottom: $SPACE_MEDIUM;
  }

  .coworkersRequired {
    padding-bottom: $SPACE_REGULAR;
    border-bottom: pixelToRem(1) solid $NEUTRAL_06;
  }

  .eventDate {
    color: $NEUTRAL_01;
    font-size: $NORMAL-FONT-SIZE;
    line-height: pixelToRem(24);
    margin-top: $SPACE_MEDIUM;
    font-weight: 600;
  }

  .eventType, .eventSourceType {
    svg {
      margin-right: $SPACE_TINY;
      color: $NEUTRAL_03;
      height: auto;
      width: auto;
      vertical-align: middle;
    }
  }


  .items {
    margin-bottom: $SPACE_TINY;
  }

  .itemsList{
    margin-bottom: $SPACE_REGULAR;
  }
}
