@import "~@rentacenter/racstrap/src/styles/vars";

.timeSlotColumn {
  display: flex;
  flex-direction: column;

  .day {
    color: $NEUTRAL_03;
    font-weight: 600;
    font-size: $NORMAL-FONT-SIZE;
    line-height: pixelToRem(24);
    margin-bottom: $SPACE_SMALL;
  }

  .timeSlotEntry {
    border: pixelToRem(1) solid $NEUTRAL_04;
    color: $PRIMARY_02;
    width: pixelToRem(190);
    text-align: center;
    padding: $SPACE_SMALL 0;
    margin-bottom: $SPACE_SMALLER;
    cursor: pointer;
    border-radius: pixelToRem(4);
    font-weight: 600;

    &.selected {
      color: white;
      background-color: $PRIMARY_02;
      border-color: $PRIMARY_02;
    }

    &.closed {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      color: $NEUTRAL_03;
      background-color: $NEUTRAL_06;
      cursor: not-allowed;
    }

    &.disabled {
      color: $NEUTRAL_03;
      background-color: $NEUTRAL_06;
      border-color: $NEUTRAL_06;
      cursor: not-allowed;

      &.disabledSelected {
        border: pixelToRem(1) solid $PRIMARY_02;
      }
    }

    &.blocked {
      color: $NEUTRAL_03;
      background: repeating-linear-gradient(
          -45deg,
          transparent,
          transparent pixelToRem(2),
          $NEUTRAL_06 pixelToRem(2),
          $NEUTRAL_06 pixelToRem(8)
      );
      border-color: $NEUTRAL_06;
      cursor: not-allowed;
    }

    &.blockSelected {
      color: $PRIMARY_03;
      background: repeating-linear-gradient(
          -45deg,
          transparent,
          transparent pixelToRem(2),
          $NEUTRAL_06 pixelToRem(2),
          $NEUTRAL_06 pixelToRem(8)
      );
      border: pixelToRem(1) solid $PRIMARY_02;
    }
  }
}
