@import "node_modules/@rentacenter/racstrap/src/styles/vars";

.container {
  font-weight: 400;
  margin-top: $SPACE_MEDIUM;
  color: $NEUTRAL_01;

  &.editMode {
    margin-top: 0;
  }

  .row {
    margin-bottom: $SPACE_MEDIUM;
    height: pixelToRem(67);

    &.split {
      display: flex;

      .left {
        width: 50%;
        margin-right: $SPACE_REGULAR;
      }

      .right {
        flex: 1;
      }
    }

    &.multiline {
      margin-bottom: 0;
      height: auto;
    }
  }
}

.buttons {
  text-align: right;
  margin-top: $SPACE_LARGE;

  button {
    margin-left: $SPACE_REGULAR;
  }

  &.editMode {
    padding-bottom: $SPACE_REGULAR;
    border-bottom: pixelToRem(1) solid $NEUTRAL_05;
  }
}
