@import '~@rentacenter/racstrap/src/styles/vars';

.popover {
  width: pixelToRem(756);

  .subHeader {
    font-size: $NORMAL-FONT-SIZE;
    line-height: pixelToRem(24);
    font-weight: 600;
    margin-bottom: $SPACE_MEDIUM;
  }

  .list {
    margin-bottom: $SPACE_LARGE;
    color: $NEUTRAL_03;
  }

  .inlineFilters {
    display: flex;

    .sourceListWrapper {
      width: pixelToRem(300);
    }
  }

  .deletedCheckbox {
    margin-top: $SPACE_LARGE;
  }

  .arrow {
    right: pixelToRem(50);
  }

  @media (max-width: 1679px) {
    .arrow {
      right: pixelToRem(25);
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
  }

  .deselectAll {
    margin-bottom: $SPACE_LARGE;
    cursor: pointer;
    color: $PRIMARY_02;
    font-weight: 600;
    font-size: $NORMAL-FONT-SIZE;

    svg {
      margin-right: $SPACE_SMALLER;
      height: pixelToRem(14);
      width: pixelToRem(14);
    }
  }

  .popoverFooter {
    display: flex;
    margin-top: $SPACE_MEDIUM;

    .btnWrapper {
      margin-left: auto;
    }

    .applyBtn {
      margin-left: $SPACE_MEDIUM;
      width: pixelToRem(70);
    }

    .cancelBtn {
      width: pixelToRem(70);
    }
  }
}

