@import "~@rentacenter/racstrap/src/styles/vars";

.noteCard {
  background-color: $PRIMARY_06;
  border: pixelToRem(1) solid $PRIMARY_05;
  padding: $SPACE_REGULAR;
  margin-bottom: $SPACE_REGULAR;
  border-radius: pixelToRem(9);
  font-size: $NORMAL-FONT-SIZE;
  font-weight: normal;

  &.ownNote {
    background-color: $NEUTRAL_07;
    border-color: $NEUTRAL_05;
  }

  .title {
    display: flex;
    margin-bottom: $SPACE_REGULAR;

    .name {
      flex: 1;
      font-weight: 600;
    }

    .date {
      font-size: $SMALL-FONT-SIZE;
    }
  }
}

.replySection {
  margin-bottom: $SPACE_LARGE;
  text-align: right;
  padding-right: $SPACE_SMALLER;

  span {
    color: $PRIMARY_02;
    cursor: pointer;
  }
}
