@import '~@rentacenter/racstrap/src/styles/vars';

.storeGoals {
  display: flex;
  flex-wrap: wrap;

  // https://stackoverflow.com/a/30891910
  // added back on .storeGoalCard in StoreGoalCard.module.scss
  margin-top: -$SPACE_REGULAR;
}
