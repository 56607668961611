@import "~@rentacenter/racstrap/src/styles/vars";

.emptyMessage {
  text-align: center;
  font-weight: 600;
  color: $NEUTRAL_01;

  .emptyIcon {
    display: inline-block;
    width: pixelToRem(105);
    height: pixelToRem(105);
    margin-bottom: $SPACE_MEDIUM;
  }
}
