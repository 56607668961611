@import "~@rentacenter/racstrap/src/styles/vars";

.actionBar {
  color: $PRIMARY_02;
  font-size: $LARGE-FONT-SIZE;

  .divider {
    border-left: pixelToRem(1) solid $NEUTRAL_06;
    margin-right: pixelToRem(15);
    margin-left: pixelToRem(15);
    height: pixelToRem(24);
  }
}
