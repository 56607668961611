@import '~@rentacenter/racstrap/src/styles/vars';

.apiError {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .textHead {
    color: $NEUTRAL_01;
    font-size: $LARGE-FONT-SIZE;
    font-weight: 700;
    text-align: center;

    svg {
      margin-right: $SPACE_SMALLER;
      color: $STATUS_RED_01;
    }
  }
  .text {
    max-width: pixelToRem(375);
    width: auto;
    margin-top: $SPACE_SMALL;
    text-align: center;
    line-height: pixelToRem(27);
    font-size: $NORMAL-FONT-SIZE;
    font-weight: 600;
    color: $NEUTRAL_02;
  }
  .errorIcon {
    width: pixelToRem(70);
  }
}
