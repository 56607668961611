@import '~@rentacenter/racstrap/src/styles/vars';

$minCardWidth: pixelToRem(290);
$maxCardWidth: pixelToRem(446);

.cardsWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $SPACE_LARGER;

  .collectionGoalCard {
    flex: 1;
    margin-top: $SPACE_REGULAR;
    margin-right: $SPACE_REGULAR;
    min-width: $minCardWidth;
    max-width: $maxCardWidth;
  }

  .collectionGoalCard:last-child {
    margin-right: 0;
  }
}

.seeFullWeek {
  color: $PRIMARY_02;
  cursor: pointer;
  float: right;
  font-weight: 600;

  .text {
    padding-left: $SPACE_SMALLER;
  }
}

.selectedDate {
  font-weight: 600;
}

.highlighted {
  color: $PRIMARY_02;
}

@media (min-width: 1024px) {
  .cardsWrapper .collectionGoalCard {
    min-width: pixelToRem(368);
  }
}
