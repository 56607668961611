@import '~@rentacenter/racstrap/src/styles/vars';

.subtitle {
  margin-bottom: $SPACE_MEDIUM;
  line-height: $SPACE_LARGE;
}

.reason {
   height: pixelToRem(86);
 }
