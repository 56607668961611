@import "~@rentacenter/racstrap/src/styles/vars";

$timeSlotHeight: 110;
$lunchTimeSlotHeight: 62;
$timeRulerWidth: 80;

.timeslot {
  position: relative;
  padding: 0.05px;
  padding-left: calc(#{pixelToRem($timeRulerWidth)} + #{$SPACE_SMALLER});
  height: pixelToRem($timeSlotHeight);
  margin-bottom: pixelToRem(6);

  &.lunch {
    height: pixelToRem($lunchTimeSlotHeight);
  }

  &.last {
    height: auto;
  }

  .title {
    display: flex;
    white-space: nowrap;
    align-items: center;
    margin-left: pixelToRem(-$timeRulerWidth);
    font-size: $SMALL-FONT-SIZE;
    margin-top: -($SMALL-FONT-SIZE / 2);

    &:after {
      content: " ";
      display: block;
      height: pixelToRem(1);
      width: 100%;
      background-color: $NEUTRAL_06;
      margin-left: $SPACE_SMALLER;
    }
  }

  &.active .title {
    font-weight: bold;
  }

  .cards {
    display: flex;
    height: 100%;
    padding-bottom: $SPACE_MEDIUM;
    width: 100%;
    overflow-x: hidden;

    &.past {
      opacity: 0.5;
    }
  }

  .arrowBkg {
    $arrowHeight: pixelToRem(60);
    $arrowWidth: pixelToRem(30);

    position: absolute;
    left: calc(#{pixelToRem($timeRulerWidth)} - #{$SPACE_MEDIUM} - (#{$arrowWidth} / 2) - 1px);
    top: 0;
    bottom: 0;
    z-index: 3;
    width: pixelToRem(100);

    &.arrowBkgRight {
      right: 0;
      left: auto;
      background: linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 22%, rgba(255,255,255,0) 100%);
    }

    .arrow {
      position: absolute;
      height: $arrowHeight;
      width: $arrowWidth;
      top: calc(50% - (#{$arrowHeight} / 2));
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $SPACE_SMALLER 0;
      justify-content: space-between;
      background-color: white;
      border: pixelToRem(1) solid $NEUTRAL_04;
      border-radius: pixelToRem(18);
      z-index: 3;
      box-shadow: 0 pixelToRem(2) pixelToRem(5) 0 rgba(91, 108, 122, 0.26);
      color: $PRIMARY_02;
      font-size: $SMALL-FONT-SIZE;
      font-weight: 600;
      cursor: pointer;

      &.arrowRight {
        right: 0;
      }
    }
  }



  .lunchCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: $NEUTRAL_03;
    font-weight: 600;
    padding-bottom: $SPACE_MEDIUM;

    .burger {
      font-size: $LARGE-FONT-SIZE;
      margin-bottom: $SPACE_TINY;
    }
  }
}

:export {
  timeSlotHeight: $timeSlotHeight;
  lunchTimeSlotHeight: $lunchTimeSlotHeight;
}
