@import '~@rentacenter/racstrap/src/styles/vars';

.selectionTitle {
  display: flex;
  flex-direction: column;
  font-size: $BIG-FONT-SIZE;
  font-weight: 600;
  line-height: pixelToRem(27);
  position: absolute;
  background-color: white;
  width: 100%;
  left: 0;
  right: 0;
  padding: 0 $SPACE_REGULAR $SPACE_MEDIUM;
  z-index: 8;
}

.upcomingEventsContent {
  overflow-y: auto;
  height: 100%;
  flex-direction: column;
  padding-top: pixelToRem(71);

  .upcomingEventsCard {
    margin-bottom: $SPACE_MEDIUM;
    height: pixelToRem(93);
  }
}

.emptyEvent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 600;

  svg {
    margin-bottom: $SPACE_MEDIUM;
  }
}

