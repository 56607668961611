@import "~@rentacenter/racstrap/src/styles/vars";

.printEventModal {
  width: pixelToRem(700);
  height: 90%;

  .modalContent {
    height: 100%;

    .preview {
      width: 100%;
      height: 100%;
    }
  }
}
