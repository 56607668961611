@import "~@rentacenter/racstrap/src/styles/vars";

.goalFormButtons {
  top: $SPACE_SMALL;
  right: $SPACE_SMALL;
  position: absolute;

  .cancel {
    width: pixelToRem(69);
  }

  .save {
    width: pixelToRem(81);
    margin-left: $SPACE_MEDIUM;
  }
}
