@import "~@rentacenter/racstrap/src/styles/vars";

.container {
  font-weight: 400;
  // TODO: robert.gyori: drop formContainer class when integrating with new time slots selector.
  // currently is required, because opening the selector causes an Y overflow on side modal.
  .formContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .name {
    font-weight: 600;
    font-size: $LARGE-FONT-SIZE;

    svg {
      margin-right: $SPACE_SMALLER;
    }
  }
  .row {
    margin-top: $SPACE_REGULAR;
  }

  .buttons {
    text-align: right;
    margin-top: $SPACE_LARGE;

    button {
      margin-left: $SPACE_REGULAR;
    }

    &.editMode {
      padding-bottom: $SPACE_REGULAR;
      border-bottom: pixelToRem(1) solid $NEUTRAL_05;
    }
  }
}
