@import '~@rentacenter/racstrap/src/styles/vars';

.selectionTable {
  margin-top: $SPACE_LARGE;

  thead tr {
    background-color: transparent;

    th {
      font-weight: normal;
      padding: $SPACE_TINY;
    }
  }

  th[data-check-cell] {
    width: 0;
  }
}
