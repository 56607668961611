@import '~@rentacenter/racstrap/src/styles/vars';

.container {
  padding-bottom: pixelToRem(222);

  .title {
    margin-top: $SPACE_LARGER;
    color: $NEUTRAL_01;
    font-size: $LARGE-FONT-SIZE;
    font-weight: bold;
    line-height: pixelToRem(30);
  }

  .card {
    margin-top: $SPACE_REGULAR;
    width: pixelToRem(1047);

    .sectionTitle {
      color: $NEUTRAL_01;
      font-weight: 600;
      font-size: $NORMAL-FONT-SIZE;
      line-height: pixelToRem(24);
    }

    .sectionDescription {
      margin: $SPACE_REGULAR 0;
      font-size:  $SMALL-FONT-SIZE;
      line-height: pixelToRem(21);
    }
  }
}



