@import '~@rentacenter/racstrap/src/styles/vars';

.toDo::before{
  content: " ";
  display: inline-block;
  width: pixelToRem(10);
  height: pixelToRem(10);
  border-radius: 100%;
  margin-right: $SPACE_SMALLER;
  margin-top: pixelToRem(1);
  background-color: $PRIMARY_01;
}

.completed {
  svg {
    color: $STATUS_GREEN_01;
    margin: 0 $SPACE_SMALLER 0 $SPACE_TINY;
  }
}

.canceled {
  svg {
    color: $STATUS_RED_01;
    margin: 0 $SPACE_SMALLER 0 $SPACE_TINY;
    vertical-align: middle;
  }
}

.deleted {
  svg {
    color: $NEUTRAL_03;
    margin: 0 $SPACE_SMALLER 0 $SPACE_TINY;
    vertical-align: middle;
  }
}
