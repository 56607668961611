@import '~@rentacenter/racstrap/src/styles/vars';

.storeGoalForm {
  position: relative;
  height: pixelToRem(85);
  width: 100%;

  .goalInputWrapper {
    margin-top: $SPACE_SMALL;
    .goalInput {
      width: pixelToRem(70);
    }
  }

  .editButtonsGroup {
    position: absolute;
    bottom: $SPACE_SMALL;
    right: 0;

    .cancel {
      width: pixelToRem(69);
    }

    .save {
      width: pixelToRem(81);
      margin-left: $SPACE_MEDIUM;
      height: pixelToRem(39);
    }
  }
}
