@import '~@rentacenter/racstrap/src/styles/vars';

.storeEvent {
  position: relative;

  &.todo {
    border-color: $SECONDARY_02;
    background-color: #e6eeee;

    .footer {
      color: $SECONDARY_02;
      svg path {
        fill: currentcolor;
      }
    }
  }

  &.completed {
    border-color: $SECONDARY_04;
    background-color: #e6f7f6;

    .footer {
      color: $SECONDARY_02;
      svg path {
        fill: currentcolor;
      }
    }
  }

  &.canceled {
    border-color: $STATUS_RED_06;
    background-color: $STATUS_RED_05;
  }

  &.deleted {
    border-color: $NEUTRAL_04;
    border-left-color: $NEUTRAL_03;
    border-top-width: thin;
    border-right-width: thin;
    border-bottom-width: thin;
    border-style: solid;
    background-color: white;
    .title {
      text-decoration: line-through;
    }
  }

  .title {
    font-weight: 600;
    font-size: $SMALL-FONT-SIZE;
    line-height: pixelToRem(20);
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;

    .statusIcon {
      float: right;
    }
  }

  .description {
    font-size: $TINY-FONT-SIZE;
    line-height: pixelToRem(18);
    margin-top: $SPACE_TINY;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: pixelToRem(35);
  }

  .footer {
    position: absolute;
    font-size: $TINY-FONT-SIZE;
    font-weight: 600;
    line-height: pixelToRem(18);
    bottom: $SPACE_SMALLER;

    svg {
      margin-right: $SPACE_SMALLER;
      height: auto;
      width: auto;
      vertical-align: middle;
    }
  }
}
