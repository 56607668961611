@import '~@rentacenter/racstrap/src/styles/vars';

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  font-size: 1rem;
  line-height: 1.15; // because bootstrap sets 1.5 in the container app
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background-color: $NEUTRAL_07;
  color: $NEUTRAL_01;
}
