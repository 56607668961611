@import '~@rentacenter/racstrap/src/styles/vars';

.category {
  display: flex;
  align-items: center;
  width: pixelToRem(50);
  text-align: center;

  &::before {
    content: ' ';
    display: block;
    width: $TINY-FONT-SIZE;
    height: $TINY-FONT-SIZE;
    background-color: currentColor;
    border-radius: 100%;
    margin-right: $SPACE_TINY;
  }
}
