@import '~@rentacenter/racstrap/src/styles/vars';
@import '../../../styles/breakpoints.scss';

.ToDo {
  background-color: $PRIMARY_06;
  color: $PRIMARY_02;
  border: pixelToRem(1) solid $PRIMARY_07;
}

.Completed {
  background-color: $STATUS_GREEN_06;
  color: $STATUS_GREEN_01;
  border: pixelToRem(1) solid $STATUS_GREEN_05;
}

.Cancelled {
  background-color: $STATUS_RED_07;
  color: $STATUS_RED_01;
  border: pixelToRem(1) solid $STATUS_RED_05;
}

.Deleted {
  background-color: $NEUTRAL_07;
  color: $NEUTRAL_03;
  border: pixelToRem(1) solid $NEUTRAL_03;
}

.selected {
  background-color: $PRIMARY_03;
  color: white;

  svg path {
    fill: currentColor;
  }
}

.iconPadding {
  margin-right: $SPACE_TINY;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: $SPACE_REGULAR;
  padding-left: $SPACE_SMALLER;
  align-items: flex-start;

  .datePickerWrap {
    display: flex;
    align-items: center;

    .datePicker {
      margin-right: $SPACE_MEDIUM;
      margin-bottom: $SPACE_TINY;
      width: pixelToRem(180);
      height: pixelToRem(47);
    }
  }

  .search {
    margin-left: auto;
  }

  .toolbarButtons {
    padding-top: $SPACE_TINY;
    display: inline-flex;

    .createEvent {
      // white-space: nowrap;
      margin-left: $SPACE_LARGEST;
    }

    .createEventOptions {
      z-index: 4;
    }
  }

  @media (max-width: 1679px) {
    .buttonText {
      display: none;
    }

    .btnIcon {
      margin-right: 0;
      margin-left: 0;
    }

    .createEvent {
      margin-left: $SPACE_REGULAR !important;
    }
  }

  @include desktopDown {
    .todayBtn {
      display: none;
    }
  }
}
