@import "~@rentacenter/racstrap/src/styles/vars";

.container {
  color: $PRIMARY_02;
  text-align: right;
  cursor: pointer;
  font-size: $SMALL-FONT-SIZE;
  line-height: pixelToRem(21);
  font-weight: 600;

  .plus {
    margin-right: $SPACE_SMALL;
  }

  svg {
    margin-right: $SPACE_TINY;
  }
}
