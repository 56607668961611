@import '~@rentacenter/racstrap/src/styles/vars';

$cardWidth: 215;
$cardMargin: remToPixel($SPACE_MEDIUM);

.card {
  height: 100%;
  width: pixelToRem($cardWidth);
  padding: $SPACE_SMALLER;
  margin-right: $SPACE_MEDIUM;
  border-radius: pixelToRem(5);
  border-left-width: pixelToRem(4);
  border-left-style: solid;
  cursor: pointer;
  z-index: 2;
  flex-shrink: 0;
}

:export {
  cardWidth: $cardWidth;
  cardMargin: $cardMargin;
}
