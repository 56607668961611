@import '~@rentacenter/racstrap/src/styles/vars';

.cardWrap {
  position: relative;

  .actions {
    position: absolute;
    right: $SPACE_REGULAR;
    top: $SPACE_REGULAR;
  }
}

.task {
  margin-bottom: $SPACE_SMALL;
  cursor: pointer;

  &.completed, &.cancelled {
    opacity: 0.5;
  }

  .title {
    font-weight: 600;
    font-size: $NORMAL-FONT-SIZE;
    line-height: pixelToRem(24);
    display: flex;
    align-items: center;

    .iconStyle {
      margin-right: $SPACE_SMALLER;
      font-size: $NORMAL-FONT-SIZE;
      color: $STATUS_GREEN_01;
    }

    .cancelColor {
      color: $STATUS_RED_01;
    }

    .name {
      flex: 1;
    }
  }

  .description {
    font-size: $SMALL-FONT-SIZE;
    line-height: pixelToRem(21);
    border-bottom: pixelToRem(1) solid $NEUTRAL_06;
    padding-bottom: $SPACE_SMALL;
    margin-top: $SPACE_TINY;
  }

  .footer {
    display: flex;
    align-items: center;
    padding-top: $SPACE_SMALL;
    font-size: $TINY-FONT-SIZE;
    font-weight: 600;

    .assignee {
      flex: 1;
      min-width: pixelToRem(70);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .category {
      margin-left: $SPACE_REGULAR;
      width: pixelToRem(50);

      &::before {
        width: $TINY-FONT-SIZE;
        height: $TINY-FONT-SIZE;
        margin-right: $SPACE_TINY;
      }
    }

    .priority {
      width: pixelToRem(60);
      margin-left: $SPACE_SMALL;
    }

    .dueDate {
      width: pixelToRem(120);
      text-align: center;

      svg {
        margin-right: $SPACE_TINY;
      }
    }

    .notes {
      width: pixelToRem(40);
      text-align: center;
      margin-left: $SPACE_MEDIUM;
    }
  }
}
