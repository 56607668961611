@import "~@rentacenter/racstrap/src/styles/vars";

.cursor {
  $leftMargin: $SPACE_HUGE;

  position: absolute;
  display: flex;
  width: calc(100% - #{$leftMargin});
  align-items: center;
  z-index: 1;
  margin-left: $leftMargin;

  &::before {
    display: block;
    content: " ";
    background-color: $NEUTRAL_01;
    border-radius: 50%;
    width: pixelToRem(12);
    height: pixelToRem(12);
  }

  &::after {
    display: block;
    content: " ";
    background-color: $NEUTRAL_01;
    height: pixelToRem(1);
    flex: 1
  }
}
