@import "~@rentacenter/racstrap/src/styles/vars";

.column {
  flex: 1;
  min-width: 0;
  height: 100%;
  display: flex;
  flex-direction: column;

  .columnTitle {
    font-weight: bold;
    font-size: $LARGE-FONT-SIZE;
    line-height: pixelToRem(30);
    margin-bottom: $SPACE_REGULAR;
    display: flex;
    align-items: center;

    .iconStyle {
      margin-right: $SPACE_SMALLER;
      font-size: $NORMAL-FONT-SIZE;
      color: $STATUS_GREEN_01;
    }

  }

  .sorting {
    margin-left: auto;
    font-size: $SMALL-FONT-SIZE;
    font-weight: 600;
    line-height: 21px;
    cursor: pointer;

    .sortText {
      padding: 0 $SPACE_MEDIUM 0 $SPACE_TINY;
      color: $NEUTRAL_03;
      svg {
        margin-right: $SPACE_TINY;
      }
    }

    .highlighted {
      color: $PRIMARY_02;
    }
  }

  .listContainer {
    overflow-y: auto;
  }

  &.todoColumn {
    .columnTitle::before {
      content: " ";
      display: inline-block;
      width: pixelToRem(10);
      height: pixelToRem(10);
      border-radius: 100%;
      margin-right: $SPACE_SMALLER;
      margin-top: pixelToRem(1);
      background-color: $PRIMARY_01;
    }
  }

  &.completedColumn {
    margin-left: $SPACE_JUMBO;
    .columnTitle::before {
      background-color: $STATUS_GREEN_01;
    }
  }
}


