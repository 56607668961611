@import '~@rentacenter/racstrap/src/styles/vars';

.list {
  margin-top: $SPACE_SMALL;

  .activeTable {
    th {
      height: pixelToRem(40);
      padding: $SPACE_SMALLER;
      font-weight: 600;
    }
  }

  .apiError {
    margin-top: $SPACE_LARGE;
  }
}
