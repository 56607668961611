@import '~@rentacenter/racstrap/src/styles/vars';

.notes {
  .title {
    margin: $SPACE_MEDIUM 0;
  }

  .emptyList {
    font-size: $SMALL-FONT-SIZE;
    color: $NEUTRAL_03;
    margin-top: $SPACE_LARGER;
    font-weight: normal;
    text-align: center;
  }
}

.notesList {
  margin-top: $SPACE_LARGE;

  .apiError {
    padding-top: $SPACE_LARGE;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      font-size: $NORMAL-FONT-SIZE;
      svg {
        margin-right: $SPACE_SMALLER;
        color: $STATUS_RED_01;
      }
    }
  }
}
