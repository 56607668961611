@import '~@rentacenter/racstrap/src/styles/vars';

.wrapper {
  margin: -$SPACE_SMALL calc(#{$SPACE_LARGEST} / -2) 0;
  box-shadow: 0 pixelToRem(4) pixelToRem(7) 0 rgba(19, 37, 46, 0.08);

  .container {
    max-width: pixelToRem(500);
    margin: 0 calc(#{$SPACE_LARGEST} / 2);
    display: flex;

    label {
      width: 25%;
      display: flex;
      margin-bottom: $SPACE_SMALL;
      align-items: center;
    }
  }
}

[collapsed-sidebar] .wrapper {
  margin: -$SPACE_SMALL calc(#{pixelToRem(80)} / -2) 0;
}

.selection {
  background-color: white;
}
