@import "~@rentacenter/racstrap/src/styles/vars";

.salesGoals {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -$SPACE_REGULAR;
  gap: $SPACE_REGULAR;
  .noResults {
    flex: 1;
  }
}
