@import '~@rentacenter/racstrap/src/styles/vars';

.title {
  margin-top: $SPACE_LARGER;
  color: $NEUTRAL_01;
  font-size: $LARGE-FONT-SIZE;
  font-weight: bold;
}

.stepper {
  margin-top: $SPACE_LARGE;
  width: pixelToRem(976);
}

.body {
  width: pixelToRem(1046);
  padding-bottom: pixelToRem(100);
}
