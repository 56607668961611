@import '~@rentacenter/racstrap/src/styles/vars';

.taskDetail {
  font-size: $NORMAL-FONT-SIZE;
  line-height: pixelToRem(24);
  font-weight: 400;

  .name {
    font-weight: bold;
    font-size: $LARGE-FONT-SIZE;
  }

  .taskStatus {
    display: flex;
    justify-content: space-between;
    margin-top: pixelToRem(13);
    margin-bottom: $SPACE_LARGER;
    align-items: center;

    .statusButton {
      padding: $SPACE_SMALLER $SPACE_SMALL;
  
      svg {
        height: pixelToRem(14);
        width: pixelToRem(14);
      }
    }

    .cancelTask {
      margin-left: $SPACE_REGULAR;
    }

    .statusField {
      display: flex;
      .status {
        &::before {
          width: pixelToRem(15);
          height: pixelToRem(15);
          margin-top: $SPACE_TINY;
        }
      }
    }
    
  }

  .description {
    flex-direction: column;
  }

  .label {
    font-weight: 600;
    margin-right: $SPACE_TINY;
  }

  .row {
    display: flex;
    margin-bottom: $SPACE_MEDIUM;

    &:last-child {
      border-bottom: pixelToRem(1) solid $NEUTRAL_06;
    }
  }

  .dueDate,
  .dueTime {
    flex: 1;
    margin-bottom: $SPACE_REGULAR;
  }

  .category {
    width: pixelToRem(60);
  }
}

.tabs span {
  font-weight: 700;
}
