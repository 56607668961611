@import '~@rentacenter/racstrap/src/styles/vars';

$cardWidth: 215;
.blockTimeEvent {
  position: relative;

  &.todo {
    min-width: pixelToRem($cardWidth);
    flex-grow: 1;
    border-color: $NEUTRAL_04;
    background-color: $NEUTRAL_06;

    .footer {
      color: $NEUTRAL_06;
      svg path {
        fill: currentcolor;
      }
    }
  }

  .title {
    font-weight: 600;
    font-size: $SMALL-FONT-SIZE;
    line-height: pixelToRem(20);
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .description {
    font-size: $TINY-FONT-SIZE;
    line-height: pixelToRem(18);
    margin-top: $SPACE_TINY;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: pixelToRem(35);
  }
}
