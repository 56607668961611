@import '~@rentacenter/racstrap/src/styles/vars';

.checkCell {
  width: 0;
}

.apiError {
  margin-top: $SPACE_REGULAR;
}

.itemsListTable {
  width: 60%;
}
