@import "~@rentacenter/racstrap/src/styles/vars";

.content {
  font-weight: 400;
  margin-top: $SPACE_MEDIUM;
  color: $NEUTRAL_01;

  &.editMode {
    margin-top: 0;

    .nonEditableWrapper {
      display: flex;
      font-size: $NORMAL-FONT-SIZE;
      line-height: pixelToRem(24);
      font-weight: 400;
      margin-bottom: $SPACE_MEDIUM;

      .label {
        font-weight: 600;
        margin-right: $SPACE_TINY;
      }

    .eventSourceType, .eventType {
        svg {
          margin-right: $SPACE_TINY;
          color: $NEUTRAL_03;
          height: auto;
          width: auto;
          vertical-align: middle;
        }
      }
    }
  }
}

.filtersRow {
  display: flex;
  margin-bottom: $SPACE_MEDIUM;
  padding-right: $SPACE_REGULAR;

  & > div {
    width: 50%;
  }

  .select label {
    line-height: inherit;
  }
}

.timeSlotRow {
  margin-top: $SPACE_SMALLER;
}

.disable {
  input:disabled {
  opacity: 0.5;
  background-color: $NEUTRAL_07;
  cursor: not-allowed;
  }
}

.description {
  margin-top: $SPACE_MEDIUM;
  input:disabled {
    opacity: 0.5;
    background-color: $NEUTRAL_07;
    cursor: not-allowed;
  }
}

.option .label {
  margin-left: $SPACE_SMALL;
}

.name {
  font-weight: 600;
  font-size: $LARGE-FONT-SIZE;
  margin-bottom: $SPACE_LARGER;

  svg {
    margin-right: $SPACE_SMALLER;
  }

}

.address {
  width: 100%;
  margin-top: $SPACE_MEDIUM;

  input:disabled {
    opacity: 0.5;
    background-color: $NEUTRAL_07;
    cursor: not-allowed;
  }

}

.buttons {
  text-align: right;
  margin-top: $SPACE_LARGER;

  button {
    margin-left: $SPACE_REGULAR;
  }

  &.editMode {
    padding-bottom: $SPACE_REGULAR;
    border-bottom: pixelToRem(1) solid $NEUTRAL_05;
  }
}
