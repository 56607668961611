@import '~@rentacenter/racstrap/src/styles/vars';

@mixin trBorder(
  $borderColor,
  $borderWidth: pixelToRem(1),
  $borderStyle: solid
) {
  border-top: $borderWidth $borderStyle $borderColor;
  border-bottom: $borderWidth $borderStyle $borderColor;

  &:first-of-type {
    border-left: $borderWidth $borderStyle $borderColor;
  }
  &:last-of-type {
    border-right: $borderWidth $borderStyle $borderColor;
  }
}

.selectableRow {
  cursor: pointer;

  td {
    // in order to avoid the resizing of the table cells
    @include trBorder(transparent);
  }

  &:hover,
  &.selected {
    td {
      @include trBorder($PRIMARY_02);
    }
  }

  td.checkCell {
    padding-right: 0;
    width: pixelToRem(32);

    svg {
      font-size: $NORMAL-FONT-SIZE;
      color: $PRIMARY_02;
    }
  }
}

