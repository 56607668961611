@import '~@rentacenter/racstrap/src/styles/vars';

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: pixelToRem(57);

  .spinnerIcon {
    color: $NEUTRAL_03;
    font-size: $LARGER-FONT-SIZE;
  }
  .loadingText {
    margin-top: $SPACE_SMALL;
    color: $NEUTRAL_03;
    font-size: $NORMAL-FONT-SIZE;
    font-weight: 600;
  }
}
