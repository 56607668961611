@import '~@rentacenter/racstrap/src/styles/vars';

.form {
  margin-top: $SPACE_LARGER;
  display: flex;
  align-items: flex-end;
  line-height: pixelToRem(16);

  > div {
    height: pixelToRem(86);
    margin-right: $SPACE_MEDIUM;
  }

  .withoutLabel {
    display: flex;
    align-items: center;
    margin-bottom: pixelToRem(17);

    // somehow racstrap components have 47px instead of 45px
    height: pixelToRem(47);
  }

  .datePicker {
    min-width: pixelToRem(228);
    background-color: #fff;
  }
}
