@import "~@rentacenter/racstrap/src/styles/vars";

.salesValueBox {
  flex: 1;
  border-left: pixelToRem(1) solid $NEUTRAL_04;
  padding: 0 $SPACE_MEDIUM;

  &:first-child {
    border-left: none;
  }

  .title {
    font-size: $NORMAL-FONT-SIZE;
    font-weight: 600;
    margin-bottom: $SPACE_SMALLER;
  }

  .value {
    font-weight: 700;
    font-size: $LARGE-FONT-SIZE;

    .empty {
      color: $NEUTRAL_04;
    }

    .green {
      color: $LABEL_GREEN_2;
    }

    .orange {
      color: $LABEL_YELLOW;
    }

    .red {
      color: $STATUS_RED_01;
    }
  }
}
