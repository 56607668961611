@import '~@rentacenter/racstrap/src/styles/vars';

.todo {
  border-color: $PRIMARY_01;
  background-color: $PRIMARY_06;
}

.completed {
  border-color: $STATUS_GREEN_01;
  background-color: $STATUS_GREEN_06;
}

.canceled {
  border-color: $STATUS_RED_06;
  background-color: $STATUS_RED_05;
}

.deleted {
  border-color: $NEUTRAL_04;
  border-left-color: $NEUTRAL_03;
  border-top-width: thin;
  border-right-width: thin;
  border-bottom-width: thin;
  border-style: solid;
  background-color: white;
  .customer {
    text-decoration: line-through;
  }
}

.customer {
  font-weight: 600;
  font-size: $SMALL-FONT-SIZE;
  line-height: pixelToRem(20);
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;

  .statusIcon {
    float: right;
  }
}

.address {
  font-size: $TINY-FONT-SIZE;
  line-height: pixelToRem(18);
  margin-top: $SPACE_TINY;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: pixelToRem(35);
}

.footer {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;

  .type,
  .source {
    font-size: $TINY-FONT-SIZE;
    font-weight: 600;
    line-height: pixelToRem(18);
    margin-top: $SPACE_TINY;

    svg {
      margin-right: $SPACE_SMALLER;
      height: auto;
      width: auto;
      vertical-align: middle;
    }
  }
}
