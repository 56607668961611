@import "~@rentacenter/racstrap/src/styles/vars";

.container {
  font-weight: 400;

  .eventStatus {
    margin-top: pixelToRem(10);
    margin-bottom: $SPACE_LARGE;
    align-items: center;

    .statusField {
      display: flex;

      .label {
        font-weight: 600;
        margin-right: $SPACE_TINY;
      }

      .status::before {
        width: pixelToRem(8);
        height: pixelToRem(8);
      }
    }
  }

  .row {
    margin-top: $SPACE_REGULAR;
  }
  
  .split {
    display: flex;

    .left {
      width: pixelToRem(232);
      margin-right: $SPACE_REGULAR;
    }

    .right {
      flex: 1;
      width: 100%;
    }
  }

  .buttons {
    text-align: right;
    margin-top: $SPACE_LARGE;

    button {
      margin-left: $SPACE_REGULAR;
    }

    &.editMode {
      padding-bottom: $SPACE_REGULAR;
      border-bottom: pixelToRem(1) solid $NEUTRAL_05;
    }
  }
}
