@import '~@rentacenter/racstrap/src/styles/vars';

.completedIcon {
  color: $STATUS_GREEN_01;
}

.canceledIcon {
  color: $STATUS_RED_01;
}

.deletedIcon {
  color: $NEUTRAL_03;
}
