@import '~@rentacenter/racstrap/src/styles/vars';

.toolbar {
  display: flex;
  justify-content: space-between;
  margin-top: $SPACE_LARGER;
  margin-bottom: $SPACE_LARGER;

  > div {
    display: flex;
    align-items: center;
  }

  .datePicker {
    background-color: #fff;
    margin: 0 $SPACE_MEDIUM 0 0;
    width: pixelToRem(200);
    height: pixelToRem(47);
  }

  .select {
    background-color: #fff;
    margin-bottom: 0;
    margin-right: $SPACE_MEDIUM;

    &.modeSelect {
      width: pixelToRem(97);
    }
    &.coworkerSelect {
      min-width: pixelToRem(260);
    }
  }

  .selection {
    margin-top: 0;
  }

  .coworkerLabel {
    color: $NEUTRAL_01;
    font-size: $NORMAL-FONT-SIZE;
    margin-right: $SPACE_MEDIUM;
  }

  .newTask {
    white-space: nowrap;
    margin-left: $SPACE_LARGER;
  }
}
